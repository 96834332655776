<div role="dialog">
<h1  role="heading"  aria-level="2" id="landingErrorInfoHeading" class="dialog_info-dialog-title"> We were unable to locate your quote</h1>
<div id="infoContent" class="infoDialogContent" class="dialog_info-dialog-content">
    Please try again or if you would like to speak to Farmers<sup>®</sup> representative immediately, call<a href="tel:18005151896" 
    aria-label=  "Contact farmers representative at 1-800-515-1896"> 1-800-515-1896</a>.<br>
     If you're an employee or member of a group with access to the Farmers GroupSelect<sup>&#8480;</sup>
      program, please call <a href="tel:1777-888-9999"  id="fws" aria-label=  "Contact farmers representative at 1-777-888-9999"> 1-777-888-9999 </a>.
</div>
<div class="dialog_info-dialog-button-holder">
  <button mat-button mat-dialog-close
  class="dialog__info-dialog-button-close close-button" (click)="dialogRef.close()"  (keydown.esc)="dialogRef.close()">CLOSE</button>
</div>
</div>